<template>
  <div class="tw-space-y-4">
    <div
      class="tw-flex tw-flex-col tw-justify-center tw-gap-2 md:tw-gap-4 tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
      <div class="tw-pb-4">
        <div
          class="tw-w-full md:tw-px-[29px] tw-py-2 tw-px-3 tw-mb-4 md:tw-py-[13px] tw-bg-[#3bd4ae10] tw-rounded-xl tw-flex-col tw-justify-center tw-items-start tw-gap-2.5 tw-flex">
          <div class="tw-justify-between tw-items-center tw-w-full tw-flex">
            <div class="tw-text-[#1e2022] tw-text-sm md:tw-text-lg tw-font-semibold tw-leading-tight">
              {{ $t('requestInterview.interviewScheduled') }}
            </div>
            <img src="@/assets/images/check.svg" class="tw-w-6 tw-h-6" alt="ic_success" />
          </div>
        </div>

        <div class="interviewCard__details">
          <img src="@/assets/images/ic_clock.svg" class="tw-me-2" alt="CLock" />
          <h4>
            <span>{{ $t("interviewsList.meetingTime") }}:</span>
            <span class="interviewCard__ltr tw-me-1" v-if="interview.interview.meeting_start_time">
              {{ meetingTime(interview.interview.meeting_start_time, interview.interview.timezone)[0] }} <span
                class="time-zone"> {{ meetingTime(interview.interview.meeting_start_time, interview.interview.timezone
                )[1] }}</span>
            </span>
            <span class="interviewCard__ltr" v-else>{{
              $t("interviewsList.loading")
              }}</span>
          </h4>
        </div>

        <div class="interviewCard__actions">
          <div class="interviewCard__copy">
            <span> {{ $t("interviewsList.interviewLink") }}: </span>
            <div>
              <span v-if="interview.interview.meeting_link" dir="ltr">
                {{ interview.interview.meeting_link | truncate }} 
              </span>
              <span dir="ltr" v-else>{{
                $t("interviewsList.loading") 
              }} {{ fetchInterview() }}</span>
              <button @click="copyInterviewLink(interview.interview.meeting_link)">
                <img src="@/assets/images/copy.svg" alt="Copy url" />
              </button>
            </div>
          </div>
        </div>
      </div>

          <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="$emit('cancel')"
              class="tw-py-3 md:tw-py-3.5 tw-w-full md:tw-w-auto  tw-rounded-lg  tw-justify-center tw-items-center tw-underline tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[12px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
          </div>
          
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentTimezone from "moment-timezone";
import toast from "@/services/toast.js";

export default {
  name: "InterviewScheduled",
  props: {
    interview: {
      type: Object,
      default: () => { },
    },
  },
  filters: {
    truncate(value) {
      return value.length > 35 ? value.substring(0, 35) + "..." : value;
    },
  },
  data() {
    return {
      durationsList: [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.durationsList = [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ];
    },
  },
  computed: {
    timezones() {
      let timezones = momentTimezone.tz.names();
      timezones = timezones.map((timezone) => {
        return {
          name: `${timezone.split("/")[1]} Time`,
          value: timezone,
        };
      });
      return timezones;
    },
  },
  methods: {
    slotFormat(slot) {
      return moment.utc(slot).format("D MMM YYYY, h:mm A");
    },
    dataFormat(date) {
      return moment(date).format("D MMM");
    },
    meetingTime(time, timezone) {
      if (this.$i18n.locale == "en") {
        moment.locale("en");
        return [`${moment(time).format("DD/MM/YYYY")} at ${moment(time).format(
          "h:mm A"
        )}`, `${this.timezones.find((tz) => tz.value == timezone)?.name}`];
      } else {
        moment.locale("ar");
        return [`${moment(time).format("YYYY/MM/DD")} فى ${moment(
          time,
        ).format("h:mm A")}`, `${this.timezones.find((tz) => tz.value == timezone)?.name
        }`];
      }
    },
    copyInterviewLink(link) {
      navigator.clipboard.writeText(link);
      toast.success(this.$t("interviewsList.copied"));
    },
    fetchInterview() {
      this.$emit("fetchInterview");
    },
  },
};
</script>

<style lang="scss" scoped>
.interviewCard__details {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;

  @media (max-width: 768px) {
    margin-top: 1rem 0;
    align-items: flex-start;
  }

  span span.time-zone {
    color: #494D55;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.394rem;
  }

  h4 {
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      font-size: 0.875rem;
      font-weight: 400;
      color: #494d55;

      span:last-child {
        font-size: 0.875rem;
        margin-top: 4px;
        font-weight: 500;
        color: #3e3e3e;
        margin-top: 0.75rem;
      }
    }
  }

  span:first-child {
    color: #1A202C;
    padding-inline-end: 8px;
    font-size: 1.0625rem;
    font-weight: 500;

  }

  span:last-child {
    color: #1A202C;
    font-size: 1rem;
    font-weight: 400;

  }

  img {
    width: 1.25rem;
    height: 1.25rem;

    @media (max-width: 768px) {
      width: 1rem;
      height: 1rem;
    }
  }
}

.interviewCard__actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    margin-top: 1.125rem;
  }

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 7px 20px;
    border-radius: 30px;
    transition: box-shadow 0.3s ease;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }

    &:hover {
      box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
    }

    span {
      white-space: nowrap;
    }
  }

  img {
    width: 8px;
    height: 13px;
  }
}

.interviewCard__copy {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
  padding: 7px 20px;
  border-radius: 27px;
  height: 2.8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 0.625rem;
  }

  span {
    font-size: 14px;
    color: #3d3d3d;
    white-space: nowrap;

    @media (max-width: 768px) {
      white-space: pre-line;
      width: 75%;
      word-break: break-word;
      color: #1b1b1b;
      font-size: 0.75rem;
    }
  }

  &>span {
    font-size: 14px;
    color: #422e87;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }

  div {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  button {
    width: 18px;
    height: 20px;
    background-color: transparent;
    outline: none;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>